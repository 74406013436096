var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"xl":"6"}},[_c('b-card',[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.t('Company Details'))+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Module'),"label-for":"vi-module"}},[_c('validation-provider',{attrs:{"name":"Module","vid":"vi-module","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"id":"vi-module","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"module_title","reduce":function (mod) { return mod.module_id; },"placeholder":_vm.t('Select Module'),"disabled":_vm.moduleDisabled,"options":_vm.modulesOption},on:{"change":_vm.handleForm},model:{value:(_vm.form1.module_ids),callback:function ($$v) {_vm.$set(_vm.form1, "module_ids", $$v)},expression:"form1.module_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Username'),"label-for":"vi-user-name"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"vi-username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-username","autocomplete":"off","placeholder":_vm.t('Username')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.username),callback:function ($$v) {_vm.$set(_vm.form1, "username", $$v)},expression:"form1.username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Employee Code'),"label-for":"vi-employee-code"}},[_c('validation-provider',{attrs:{"name":"Employee Code","vid":"vi-employee-code","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-employee-code","autocomplete":"off","placeholder":_vm.t('Employee Code')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.orange_hrms_code),callback:function ($$v) {_vm.$set(_vm.form1, "orange_hrms_code", $$v)},expression:"form1.orange_hrms_code"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Company Name'),"label-for":"vi-company-name"}},[_c('validation-provider',{attrs:{"name":"Company Name","vid":"vi-company-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-company-name","type":"text","autocomplete":"off","placeholder":_vm.t('Company Name')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.company_name),callback:function ($$v) {_vm.$set(_vm.form1, "company_name", $$v)},expression:"form1.company_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Owner Name'),"label-for":"vi-owner-name"}},[_c('validation-provider',{attrs:{"name":"Owner Name","vid":"vi-ownername","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SmartphoneIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-owner-name","type":"text","autocomplete":"off","placeholder":_vm.t('Owner Name')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.company_owner_name),callback:function ($$v) {_vm.$set(_vm.form1, "company_owner_name", $$v)},expression:"form1.company_owner_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{class:_vm.req ? 'required' : '',attrs:{"label":_vm.t('Password'),"label-for":"vi-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"vi-password","rules":_vm.req ? 'required|min:8|password' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-password","type":_vm.passwordFieldType,"placeholder":_vm.t('Password')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.password),callback:function ($$v) {_vm.$set(_vm.form1, "password", $$v)},expression:"form1.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{class:_vm.req ? 'required' : '',attrs:{"label":_vm.t('Confirm Password'),"label-for":"vi-confirm-password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","vid":"vi-confirm-password","rules":_vm.req ? 'required|confirmed:vi-password' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"vi-confirm-password","type":_vm.passwordFieldType,"placeholder":_vm.t('Confirm Password')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.c_password),callback:function ($$v) {_vm.$set(_vm.form1, "c_password", $$v)},expression:"form1.c_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('GST No.'),"label-for":"vi-gst-no"}},[_c('validation-provider',{attrs:{"name":"GST No.","vid":"vi-gst_no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"vi-gst-no","type":"text","autocomplete":"off","placeholder":_vm.t('GSTIN')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.gst_no),callback:function ($$v) {_vm.$set(_vm.form1, "gst_no", $$v)},expression:"form1.gst_no"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('PAN'),"label-for":"vi-pan"}},[_c('validation-provider',{attrs:{"name":"PAN","vid":"vi-pan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"vi-pan","type":"text","autocomplete":"off","placeholder":_vm.t('PAN No.')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.pan),callback:function ($$v) {_vm.$set(_vm.form1, "pan", $$v)},expression:"form1.pan"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('CIN No.'),"label-for":"vi-cin-no"}},[_c('validation-provider',{attrs:{"name":"CIN No.","vid":"vi-cin-no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"vi-cin-no","type":"text","autocomplete":"off","placeholder":_vm.t('CIN No.')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.cin_no),callback:function ($$v) {_vm.$set(_vm.form1, "cin_no", $$v)},expression:"form1.cin_no"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Email'),"label-for":"vi-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"vi-email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-email","type":"email","autocomplete":"off","placeholder":_vm.t('Email')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.company_email),callback:function ($$v) {_vm.$set(_vm.form1, "company_email", $$v)},expression:"form1.company_email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Mobile'),"label-for":"vi-mobile"}},[_c('validation-provider',{attrs:{"name":"Mobile","vid":"vi-mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SmartphoneIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-mobile","type":"number","autocomplete":"off","placeholder":_vm.t('Mobile')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.mobile),callback:function ($$v) {_vm.$set(_vm.form1, "mobile", $$v)},expression:"form1.mobile"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Status'),"label-for":"vi-status"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"vi-status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"id":"vi-status","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","autocomplete":"off","reduce":function (mod) { return mod.value; },"placeholder":_vm.t('Select Status'),"options":_vm.statusOption},on:{"input":_vm.handleForm},model:{value:(_vm.form1.status),callback:function ($$v) {_vm.$set(_vm.form1, "status", $$v)},expression:"form1.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }