var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"xl":"6"}},[_c('b-card',[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.t('Contact Details'))+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Address Line 1'),"label-for":"vi-address-line1"}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"vi-address-line1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-address-line1","type":"text","autocomplete":"off","placeholder":_vm.t('Address Line 1')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.address1),callback:function ($$v) {_vm.$set(_vm.form1, "address1", $$v)},expression:"form1.address1"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('Address Line 2'),"label-for":"vi-address-line2"}},[_c('validation-provider',{attrs:{"name":"Address Line 2","vid":"vi-address-line2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-address-line2","type":"text","autocomplete":"off","placeholder":_vm.t('Address Line 2')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.address2),callback:function ($$v) {_vm.$set(_vm.form1, "address2", $$v)},expression:"form1.address2"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Phone (O)'),"label-for":"vi-phone-office"}},[_c('validation-provider',{attrs:{"name":"Phone Office","vid":"vi-phone-office","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SmartphoneIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-phone-office","type":"number","autocomplete":"off","placeholder":_vm.t('Phone Office')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.phone_office),callback:function ($$v) {_vm.$set(_vm.form1, "phone_office", $$v)},expression:"form1.phone_office"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Phone (R)'),"label-for":"vi-phone-residence"}},[_c('validation-provider',{attrs:{"name":"Phone Residence","vid":"vi-phone-residence","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"PhoneIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-phone-residence","type":"number","autocomplete":"off","placeholder":_vm.t('Phone Residence')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.phone_home),callback:function ($$v) {_vm.$set(_vm.form1, "phone_home", $$v)},expression:"form1.phone_home"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Fax'),"label-for":"vi-fax"}},[_c('validation-provider',{attrs:{"name":"Fax","vid":"vi-fax","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-fax","type":"text","autocomplete":"off","placeholder":_vm.t('Fax')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.fax),callback:function ($$v) {_vm.$set(_vm.form1, "fax", $$v)},expression:"form1.fax"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Zip Code'),"label-for":"vi-zip"}},[_c('validation-provider',{attrs:{"name":"Zip","vid":"vi-zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapPinIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-zip","type":"text","autocomplete":"off","placeholder":_vm.t('Zip Code')},on:{"change":_vm.handleForm},model:{value:(_vm.form1.zipcode),callback:function ($$v) {_vm.$set(_vm.form1, "zipcode", $$v)},expression:"form1.zipcode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Country'),"label-for":"vi-country"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"vi-country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"options":_vm.country,"label":"country_name","selected":_vm.form1.country,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":_vm.t('Select Country'),"reduce":function (c) { return c.country_id; },"disabled":_vm.countryDisabled,"autocomplete":"off"},on:{"change":_vm.handleForm,"input":_vm.countrySelected},model:{value:(_vm.form1.country),callback:function ($$v) {_vm.$set(_vm.form1, "country", _vm._n($$v))},expression:"form1.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('State'),"label-for":"vi-state"}},[_c('validation-provider',{attrs:{"name":"State","vid":"vi-state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":_vm.t('Select State'),"options":_vm.state,"label":"state_name","reduce":function (s) { return s.state_id; },"autocomplete":"off","disabled":_vm.stateDisabled},on:{"change":_vm.handleForm,"input":_vm.stateSelected},model:{value:(_vm.form1.state),callback:function ($$v) {_vm.$set(_vm.form1, "state", _vm._n($$v))},expression:"form1.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('City'),"label-for":"vi-city"}},[_c('validation-provider',{attrs:{"name":"City","vid":"vi-city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"city","reduce":function (city) { return city.location_id; },"disabled":_vm.cityDisabled,"placeholder":_vm.t('Select City'),"autocomplete":"off","options":_vm.city},on:{"input":_vm.handleForm},model:{value:(_vm.form1.city),callback:function ($$v) {_vm.$set(_vm.form1, "city", _vm._n($$v))},expression:"form1.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }